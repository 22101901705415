<template>
	<v-navigation-drawer v-model="active" app :absolute="!mobile" clipped disable-resize-watcher :height="!mobile ? '50%' : '100%'" :width="!mobile ? '25%' : '40%'" class="profile-navbar">
		<div style="width: 100%; text-align: left; padding-top: 25px;">
			<v-row>
				<v-col style="max-width:150px; margin-left:3%" justify="center">
					<router-link to="/">
						<breb-async-image :external-source="BrebLogo" :max-width="$vuetify.breakpoint.mobile ? 150 : 200" :max-height="$vuetify.breakpoint.mobile ? 60 : 70"/>
					</router-link>
				</v-col>
				<v-col style="margin-left:-15px; ">
					<h4 style="height:100%; display:flex;align-items: center;color:#0B4073;font-size: 18px; font-weight: 500;">
						Realty Shopping Simplified </h4>
				</v-col>
			</v-row>
		</div>

		<v-divider class="mb-0" style="opacity:0%;"/>

		<!-- USER BANNER -->
		<v-row v-if="user && loggedIn" justify="end" style="padding-right:30px;">
			<div class="userBanner">
				<!-- user image -->
				<v-col style="padding:0px;max-width: 106px;">
					<div v-if="user && loggedIn" style=" max-width: 106px;text-align: center;  cursor: pointer;" @click="openPictureSelectDialog">
						<v-overlay absolute v-if="profilePictureUploading">
							Uploading Profile Picture
							<v-progress-circular indeterminate color="amber"></v-progress-circular>
						</v-overlay>
						<div style="display:flex; justify-content: center;" v-if="user.profilePicture">
							<div>
								<breb-async-image :id="user.profilePicture" quality="thumbnail" width="100" height="100"/>
							</div>
						</div>
						<div style="display: flex; justify-content: center;" v-else>
							<v-icon size="100" class="outlined-box">
								mdi-account
							</v-icon>
						</div>
					</div>
				</v-col>
				<!-- user text -->
				<v-col>
					<div v-if="user && loggedIn" class="welcomeMessage">
						Welcome back to <span style="font-weight: 700;"> MyBREB </span> <br/> {{ user.firstName }} {{ user.lastName }}
					</div>
				</v-col>
			</div>
		</v-row>

		<div v-if="user && loggedIn">
			<v-file-input style="display: none;" ref="fileSelect" @change="uploadProfilePicture"/>
		</div>

		<v-divider v-if="user && loggedIn" style="opacity:0%"/>

		<v-list class="secondary--text" dense nav subheader>
			<!-- myBREB account -->
			<v-list-item v-if="user && loggedIn" to="/dashboard/profile">
				<v-list-item-icon>
					<v-icon>fa-user-alt</v-icon>
				</v-list-item-icon>
				<v-list-item-title><h3>MyBREB Account</h3></v-list-item-title>
			</v-list-item>

			<v-list-item v-if="user && loggedIn && isPartner && !stripeAuthStatus" to="/api/v1/stripe/oauth/authorize">
				<v-list-item-icon>
					<v-icon>fa-user-alt</v-icon>
				</v-list-item-icon>
				<v-list-item-title><h3>Connect to Stripe</h3></v-list-item-title>
			</v-list-item>

			<!-- notifications -->
			<v-list-item v-if="user && loggedIn" class="px-0 menu-dropdown" :class="inboxMenuClass">
				<v-list-group style="width: 100%; height: 100%" :value="isInboxMenuOpen" class="secondary--text ">
					<template v-slot:activator>
						<v-list-item-content style="margin-left: 32px;">
							<v-list-item-title><h3>Notifications</h3></v-list-item-title>
						</v-list-item-content>
						<div v-if="notificationsUnread && notificationsUnread !== 0" class="amount-wrapper">
							<v-list-item-title class="text-center amount">{{ notificationsUnread }}
							</v-list-item-title>
						</div>
					</template>
					<v-list-item :to="{ name: inboxMenuItem.name }" style="padding-left: 35px; " v-for="(inboxMenuItem, i) in inboxMenu" :key="i">
						<v-list-item-icon>
							<v-icon v-text="inboxMenuItem.icon"></v-icon>
						</v-list-item-icon>
						<v-list-item-title v-text="inboxMenuItem.text"></v-list-item-title>
						<v-list-item-title class="text-center amount" v-if="inboxMenuItem.amount !== 0" v-text="inboxMenuItem.amount"></v-list-item-title>
					</v-list-item>
					<v-divider inset class="mb-2" style="opacity:70%;"/>
				</v-list-group>
			</v-list-item>

			<!-- My Listings -->
			<v-list-item v-if="user && loggedIn" to="/dashboard/listings">
				<v-list-item-icon>
					<v-icon>mdi-book</v-icon>
				</v-list-item-icon>
				<v-list-item-title><h3>My Listings</h3></v-list-item-title>
			</v-list-item>

			<!-- My favourites -->
			<v-list-item style="position: relative" to="/dashboard/bookmarks" v-if="user && loggedIn">
				<v-list-item-icon>
					<v-icon>mdi-cards-heart</v-icon>
				</v-list-item-icon>
				<v-list-item-title style="min-width: 130px">
					<h3>Favourites</h3>
				</v-list-item-title>
				<div class="amount-wrapper" v-if="bookmarksTotalElements !== null && bookmarksTotalElements !== 0">
					<v-list-item-title class="text-center amount">
						{{ bookmarksTotalElements }}
					</v-list-item-title>
				</div>
			</v-list-item>

			<!-- Billing and payments -->
			<v-list-item active-class="" to="/dashboard/stripe" v-if="user && loggedIn">
				<v-list-item-icon>
					<v-icon>mdi-credit-card-outline</v-icon>
				</v-list-item-icon>
				<v-list-item-title><h3>Billing and Payments</h3></v-list-item-title>
			</v-list-item>

			<!-- Sign Out -->
			<v-list dense nav subheader v-if="this.loggedIn">
				<v-list-item @click="logout" style="padding-left: 0px;padding-right: 0px;">
					<v-list-item-icon>
						<v-icon>logout</v-icon>
					</v-list-item-icon>
					<v-list-item-title><h3>Logout</h3></v-list-item-title>
				</v-list-item>
			</v-list>

			<v-divider v-if="user && loggedIn"/>

			<!-- get started -->
			<v-list-item to="/GetStarted">
				<v-list-item-icon>
					<v-icon>fa-arrow-circle-right</v-icon>
				</v-list-item-icon>
				<v-list-item-title>
					<h3>Get Started</h3>
				</v-list-item-title>
			</v-list-item>

			<!-- home -->
			<v-list-item to="/">
				<v-list-item-icon>
					<v-icon>fa-home</v-icon>
				</v-list-item-icon>
				<v-list-item-title>
					<h3>Home</h3>
				</v-list-item-title>
			</v-list-item>

			<!-- about -->
			<v-list-item to="/about">
				<v-list-item-icon>
					<v-icon>fa-info</v-icon>
				</v-list-item-icon>
				<v-list-item-title><h3>About</h3></v-list-item-title>
			</v-list-item>

			<!-- Brokerage -->
			<v-list-item class="px-0 menu-dropdown" :class="brokerageMenuClass">
				<v-list-group style="width: 100%; height: 100% " :value="isBrokerageMenuOpen" class="secondary--text ">
					<template v-slot:activator>
						<v-list-item-content style="margin-left: 32px;">
							<v-list-item-title><h3>Brokerage</h3></v-list-item-title>
						</v-list-item-content>
					</template>

					<v-list-item :to="{ name: brokerageMenuItem.name }" style="padding-left: 35px" v-for="(brokerageMenuItem, i) in brokerageMenu" :key="i">
						<v-list-item-icon>
							<div :class="brokerageMenuItem.iconClass" style="color:#0b4073"></div>
						</v-list-item-icon>
						<v-list-item-title v-text="brokerageMenuItem.text"></v-list-item-title>
					</v-list-item>
					<v-divider inset class="mb-2" style="opacity:70%;"/>
				</v-list-group>
			</v-list-item>

			<!-- Sign In -->
			<v-list-item to="/dashboard/login" v-if="!this.loggedIn">
				<v-list-item-icon>
					<v-icon>fa-user-alt</v-icon>
				</v-list-item-icon>
				<v-list-item-title><h3>Sign In</h3></v-list-item-title>
			</v-list-item>

			<!-- <v-subheader inset>My Account</v-subheader> -->
			<v-list-item @click="clickPostListing()" :two-line="isMerchant">
				<!-- POST IT FREE -->
				<v-list-item style="padding-left: 0px;padding-right: 0px;">
					<v-list-item-icon>
						<v-icon>fa-store</v-icon>
					</v-list-item-icon>
					<v-list-item-title>
						<h3>Post It Free</h3>
					</v-list-item-title>
				</v-list-item>

				<v-list-item-content>
					<v-list-item-subtitle v-if="isMerchant">
						<small style="color: green;" class="text-decoration-underline"> Merchant Access Approved </small>
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<v-list-item to="/TeamMerchant">
				<v-list-item-icon>
					<v-icon>fa-building</v-icon>
				</v-list-item-icon>
				<v-list-item-title><h3>Team Merchant AD</h3></v-list-item-title>
			</v-list-item>
		</v-list>

		<v-list v-if="isPartner && loggedIn" dense nav subheader>
			<v-subheader inset>BREB Sales Program</v-subheader>
			<!--			<v-list-item @click="openPartnerSetup" >-->
			<!--				<v-list-item-icon>-->
			<!--					<v-icon>mdi-handshake</v-icon>-->
			<!--				</v-list-item-icon>-->
			<!--				<v-list-item-title><h3 style="color: red;">Manager Setup <v-icon style="color: red;">mdi-alert</v-icon></h3></v-list-item-title>-->
			<!--			</v-list-item>-->
			<v-list-item to="/dashboard/manager-account" v-if="user && user.privileges.indexOf('PARTNER_PERMISSION') !== -1">
				<v-list-item-icon>
					<v-icon>mdi-handshake</v-icon>
				</v-list-item-icon>
				<v-list-item-title><h3 style="color: green;">Manager Account</h3></v-list-item-title>
			</v-list-item>
		</v-list>

		<v-list v-if="isAdministrator && loggedIn" dense nav subheader>
			<v-subheader inset>Administrator Zone</v-subheader>
			<v-list-item to="/dashboard/admin/zones">
				<v-list-item-icon>
					<v-icon>donut_small</v-icon>
				</v-list-item-icon>
				<v-list-item-title><h3>MyBREB Board Settings</h3></v-list-item-title>
			</v-list-item>
			<v-list-item to="/dashboard/admin/homepage">
				<v-list-item-icon>
					<v-icon>build</v-icon>
				</v-list-item-icon>
				<v-list-item-title><h3>Homepage Settings</h3></v-list-item-title>
			</v-list-item>
			<v-list-item to="/dashboard/admin/users">
				<v-list-item-icon>
					<v-icon>mdi-account</v-icon>
				</v-list-item-icon>
				<v-list-item-title><h3>User Directory</h3></v-list-item-title>
			</v-list-item>
			<v-list-item to="/dashboard/admin/stripe">
				<v-img src="@/assets/payment-icons/stripe.svg" width="55" height="55" contain/>
				<v-list-item-title>
					<h3>Settings</h3>
				</v-list-item-title>
			</v-list-item>
		</v-list>
		<v-divider style="opacity:0%"/>

		<ListingPermissionModal ref="postListingPermissionDialog"></ListingPermissionModal>
	</v-navigation-drawer>
</template>

<script>
import BrebAsyncImage from "@/components/accessory/BrebImage";
import ListingPermissionModal from "./ListingPermissionModal/ListingPermissionModal.vue";
import BrebLogo from "@/assets/breb.rect.logo.new.png";

export default {
	name: "ProfileNavbar",
	components: {BrebAsyncImage, ListingPermissionModal},
	props: {
		value: {
			type: Boolean,
		},
		loading: {},
	},
	data() {
		return {
			BrebLogo,
			stripeAuthStatus: null,
			profilePictureUploading: false,
			isInboxMenuOpen: false,
			isBrokerageMenuOpen: false,

			brokerageMenu: [
				{
					text: "Find Brokerages",
					iconClass: "icon-brokerage-search",
					name: "DashboardSearchBrokerage",
				},
				{
					text: "My Brokerages",
					iconClass: "icon-brokerage-my",
					name: "BrokerageList",
				},
			],

			inboxMenu: [
				{
					text: "Inbox",
					icon: "mdi-email-multiple-outline",
					name: "NotificationsList",
					amount: null,
				},
				{
					text: "Blocked Users",
					icon: "mdi-account-cancel-outline",
					name: "NotificationsIgnored",
				},
			],
		};
	},

	mounted() {
		this.initMenuBrokerageOpen();
		this.initMenuInboxOpen();
	},
	computed: {
		active: {
			get() {
				if (!this.mobile) return this.dashboard;

				return this.value;
			},
			set(v) {
				this.$emit("input", v);
			},
		},
		loggedIn() {
			return this.$store.getters["user/getLoggedIn"];
		},
		mobile() {
			return this.$vuetify.breakpoint.mobile;
		},
		dashboard() {
			return (
				this.$route.path.startsWith("/dashboard") &&
				this.$route.meta.nav !== false
			);
		},
		user() {
			return this.$store.getters["user/getUser"];
		},

		notificationsUnread() {
			return this.$store.getters["api/inbox/getUnreadCount"];
		},

		isMerchant: function () {
			if (this.isAdministrator) return true;

			if (!this.user || !this.user.privileges) return false;

			return this.user.privileges.indexOf("MERCHANT_PERMISSION") >= 0;
		},
		hasPaymentMethod() {
			if (!this.user) return false;

			return (
				this.user.customer &&
				this.user.customer.card &&
				this.user.customer.card.last4
			);
		},
		isPartner: function () {
			if (!this.user || !this.user.privileges) return false;

			return this.user.privileges.indexOf("PARTNER_PERMISSION") >= 0;
		},
		isAdministrator: function () {
			if (!this.user || !this.user.privileges) return false;

			return this.user.privileges.indexOf("ADMINISTRATOR") >= 0;
		},

		brokerageMenuClass() {
			return {
				"menu-dropdown--active": this.isBrokerageRoute,
			};
		},

		inboxMenuClass() {
			return {
				"menu-dropdown--active": this.isInboxRoute,
			};
		},

		isInboxRoute() {
			let arr = ["NotificationsThread", ...this.inboxMenu.map((v) => v.name)];
			return arr.includes(this.$route.name);
		},

		isBrokerageRoute() {
			let arr = [...this.brokerageMenu.map((v) => v.name)];
			return arr.includes(this.$route.name);
		},

		bookmarksTotalElements() {
			return this.$store.getters["api/bookmarks/getTotalElements"];
		},

		isPostingAllowed() {
			return this.$store.getters["user/isPostingAllowed"];
		},
		initStripeAuthStatus() {
			this.$store.dispatch('user/stripeAuthStatus').then((response) => {
				this.stripeAuthStatus = response.data.connected;
			});
		},
	},
	methods: {
		initMenuBrokerageOpen() {
			this.isBrokerageMenuOpen =
				this.isBrokerageRoute || this.isBrokerageMenuOpen;
		},

		initMenuInboxOpen() {
			this.isInboxMenuOpen = this.isInboxRoute || this.isInboxMenuOpen;
		},

		openPictureSelectDialog() {
			this.$refs.fileSelect.$refs.input.click();
		},
		uploadProfilePicture(file) {
			if (file) {
				this.profilePictureUploading = true;
				this.$store
				.dispatch("image/uploadImages", {fileList: [file]})
				.then((response) => {
					// We only uploaded one image
					let id = response[0].imageId;

					// Copy the user object and modify it
					let userObject = Object.assign({}, this.user);
					userObject.profilePicture = id;

					// Send the request, update the current user object.
					this.$axios
					.put("/api/v1/user/", userObject)
					.then(() => {
						this.$toasted.success(
							"Updated user profile picture successfully."
						);
						this.$store.dispatch("user/getUser");
					})
					.catch((e) => {
						this.$toasted.error(
							"Could not update user profile picture. " + e
						);
					})
					.finally(() => {
						this.profilePictureUploading = false;
					});
				})
				.error(() => {
					this.profilePictureUploading = false;
				});
			} else this.$toasted.error("Invalid file selected.");
		},

		hasPermission(permission) {
			return (
				permission === "ALL" || (permission === "ADMINISTRATOR" && this.isAdmin)
			);
		},
		openCustomerPortal() {
			window.open("/api/v1/stripe/customer/session", "_blank");
		},
		openPartnerPortal() {
			window.open("/api/v1/stripe/partner/login", "_blank");
		},
		openPartnerSetup() {
			window.open("/api/v1/stripe/partner/setup", "_blank");
		},
		async logout() {
			this.$emit("input", false);
			return this.$store.dispatch("user/logout");
		},

		clickPostListing() {
			if (!this.isPostingAllowed) {
				this.$refs.postListingPermissionDialog.isShown = true;
				return;
			}

			this.$router.push("/dashboard/listing/post");
		},
	},
	watch: {
		mobile() {
			this.$emit("input", false);
		},

		"$route.name"() {
			this.initMenuBrokerageOpen();
			this.initMenuInboxOpen();
		},

		notificationsUnread(v) {
			this.inboxMenu.some((menuItem) => {
				if (menuItem.name === "NotificationsList") {
					menuItem.amount = v;
				}
			});
		},
	},
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";
@import "../../../node_modules/vuetify/src/styles/settings/variables";
@import "../../globals";

.profile-navbar {
	.amount-wrapper {
		background-color: $dark-blue;
		position: absolute;
		left: 23px;
		top: 5px;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		padding-left: 3px;
		padding-right: 3px;

		.amount {
			color: white;
			line-height: 22px !important;
			font-size: 11px;
		}
	}

	.menu-dropdown {
		.v-list-group__header {
			background-color: transparent;
			color: rgba(0, 0, 0, 0.87);
		}

		.v-list-group--active
		.v-list-group__header
		.v-list-group__header__prepend-icon {
			.v-icon {
				color: rgba(0, 0, 0, 0.54);
			}
		}

		&.menu-dropdown--active {
			.v-list-group__header {
				color: var(--v-secondary-base);

				&::before {
					opacity: 0.12;
					background-color: var(--v-secondary-base);
					bottom: 0;
					content: "";
					left: 0;
					pointer-events: none;
					position: absolute;
					right: 0;
					top: 0;
					transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
				}

				&.v-list-item--active:not(:hover):not(:focus):before {
					opacity: 0.12;
				}

				&.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
					color: var(--v-secondary-base);

					.v-icon {
						color: var(--v-secondary-base);
					}
				}
			}

			.v-list-group--active
			.v-list-group__header
			.v-list-group__header__prepend-icon {
				.v-icon {
					color: var(--v-secondary-base);
				}
			}
		}
	}

	.icon-breb-board {
		background-image: url("../../assets/subscription/pin-gray.svg");
		width: 24px;
		background-size: contain;
	}

	.icon-brokerage-search {
		background-image: url("../../assets/brokerage/search-brokerage.png");
		width: 24px;
		background-size: contain;
	}

	.icon-brokerage {
		background-image: url("../../assets/brokerage/brokerage.png");
		width: 24px;
		background-size: contain;
	}

	.icon-brokerage-my {
		background-image: url("../../assets/brokerage/my-brokerage.png");
		width: 24px;
		background-size: contain;
	}

	.welcomeMessage {
		color: #fff;
	}

	.userBanner {
		background-color: #0b4073;
		height: 106px;
		width: 93%;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		max-width: 375px;
	}

	.v-list-item__title {
		color: #0b4073;
	}

	.v-list-item__title h3 {
		text-align: start;
		font-weight: 400;
		text-transform: uppercase;
	}

	.v-icon {
		color: #0b4073;
	}

	.v-list-group
	.v-list-group__header
	.v-list-item__icon.v-list-group__header__append-icon {
		min-width: 0px;
	}

	.v-list-item__icon {
		justify-content: center;
	}
}

@media only screen and (max-width: 700px) {
	.profile-navbar.v-navigation-drawer.v-navigation-drawer--clipped.v-navigation-drawer--fixed.v-navigation-drawer--is-mobile.v-navigation-drawer--open {
		width: 65% !important;
	}
}

@media only screen and (max-width: 450px) {
	.profile-navbar.v-navigation-drawer.v-navigation-drawer--clipped.v-navigation-drawer--fixed.v-navigation-drawer--is-mobile.v-navigation-drawer--open {
		width: 80% !important;
	}
}

.v-list-group > .v-list-group__header.v-list-item {
	display: flex;
	flex-direction: row-reverse;
}

.v-list-item__icon.v-list-group__header__append-icon {
	margin-left: 0px !important;
}
</style>
