<template>
	<v-btn :text="text" :color="icon ? '#0b4073' : color" :small="small" :x-small="xSmall" :large="large"
		:x-large="xLarge" :absolute="absolute" :left="left" :right="right" :bottom="bottom" :top="top"
		:rounded="rounded" :disabled="disabled" :outlined="outlined" :href="href" :style="{
		'background-color': icon ? 'transparent' : backgroundColor,
		'text-transform': textTransform,
		'text-decoration': link ? 'underline' : 'none',
		'font-weight': fontWeight,
		...btnStyle,
	}" :class="fontSize + ' ' + btnClass" @click="handleClick" :link="link" :plain="plain" :ripple="ripple" :icon="icon">
		<slot></slot>
	</v-btn>
</template>

<script>

export default {
	name: "BrebBtn",
	props: {
		text: {
			type: Boolean,
			default: true
		},
		color: {
			type: String,
			default: "white"
		},
		backgroundColor: {
			type: String,
			default: "#0b4073"
		},
		textTransform: {
			type: String,
			default: "none"
		},
		fontWeight: {
			type: String,
			default: "normal"
		},
		disabled: {
			type: Boolean,
			default: false
		},
		outlined: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		xSmall: {
			type: Boolean,
			default: false
		},
		large: {
			type: Boolean,
			default: false
		},
		xLarge: {
			type: Boolean,
			default: false
		},
		absolute: {
			type: Boolean,
			default: false
		},
		left: {
			type: Boolean,
			default: false
		},
		right: {
			type: Boolean,
			default: false
		},
		bottom: {
			type: Boolean,
			default: false
		},
		top: {
			type: Boolean,
			default: false
		},
		rounded: {
			type: Boolean,
			default: false
		},
		click: {
			type: Function,
			default: () => { }
		},
		link: {
			type: Boolean,
			default: false
		},
		plain: {
			type: Boolean,
			default: false
		},
		ripple: {
			type: Boolean,
			default: true
		},
		btnStyle: {
			type: Object,
		},
		btnClass: {
			type: String,
		},
		fontSize: {
			type: String,
			default: 'text-body-1'
		},
		icon: {
			type: Boolean,
			default: false
		},
		href: {
			type: String,
			default: undefined
		},
	},
	data: function () {
		return {

		}
	},
	computed: {

	},
	methods: {
		handleClick(v) {
			this.$emit('click', v);
		},
	},
	mounted() {

	}
}
</script>

<style scoped></style>