import router from '../../router';
import axios from 'axios';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual'

export default {
	namespaced: true,
	state: {
		location: ""
	},
	actions: {
		performQueryRouting: async function (ctx, route) {
			let query = route.query;
			let existingRoute = router.currentRoute;
			Object.keys(query).forEach(key => {
				if (isObject(query[key])  && key !== "tags") {
					query[key] = JSON.stringify(query[key]);
				}

				if (query[key] == null)
					delete query[key];
			})

			let newPath = `/search/v2/${route.category}/${route.subcategory}`;

			if (isEqual(route.path, newPath) && isEqual(route.query, existingRoute.query))
				return Promise.resolve(existingRoute);
			else
				return router.push({ path: `/search/v2/${route.category}/${route.subcategory}`, query: query }).catch(() => {
				});
		},
		performServerSideQuery: async function (ctx, { page, category, options }) {
			let type = category;
			let query;
			let start, size;

			if (page)
				({ start, size } = page);
			else
				[start, size] = [0, 16];

			// Allow override of query type
			if (category)
				type = category;



			// Allow override of query, otherwise build our own using the queryRequest parameters plus a default search.
			if (options)
				query = { ...options };
			else
				query = {};

			if (!query['distance'] || query['distance'] === 0) {
				query['distance'] = 80000;
			}

			// Sending null keys is bad.
			Object.keys(query).forEach(key => {
				if (query[key] == null)
					delete query[key];
			});

			return axios.post(`/api/v1/${type}/search`, query, {
				params: {
					start,
					size
				}
			});
		},
		getSponsorsForQuery: function (ctx) {
			let q = {}
			q.merchantType = "LOCAL_SPONSORS";

			return this.performServerSideQuery(ctx, {
				type: "merchant",
				query: q
			});
		}
	},
	getters: {},
	mutations: {}
};
