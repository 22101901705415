var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',{class:_vm.fontSize + ' ' + _vm.btnClass,style:({
	'background-color': _vm.icon ? 'transparent' : _vm.backgroundColor,
	'text-transform': _vm.textTransform,
	'text-decoration': _vm.link ? 'underline' : 'none',
	'font-weight': _vm.fontWeight,
	..._vm.btnStyle,
}),attrs:{"text":_vm.text,"color":_vm.icon ? '#0b4073' : _vm.color,"small":_vm.small,"x-small":_vm.xSmall,"large":_vm.large,"x-large":_vm.xLarge,"absolute":_vm.absolute,"left":_vm.left,"right":_vm.right,"bottom":_vm.bottom,"top":_vm.top,"rounded":_vm.rounded,"disabled":_vm.disabled,"outlined":_vm.outlined,"href":_vm.href,"link":_vm.link,"plain":_vm.plain,"ripple":_vm.ripple,"icon":_vm.icon},on:{"click":_vm.handleClick}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }