import BillboardMap from "@/components/license/BillboardMap/BillboardMap.vue";
import BrebAsyncImage from "@/components/accessory/BrebImage";
import SearchBillboard from "../../../components/license/SearchBillboard/SearchBillboard.vue";
import SubscribePlan from "../../../components/license/SubscribePlan/SubscribePlan.vue";
import AdListingModal from "../../../components/license/AdListingModal/AdListingModal.vue";
import { subscribtion } from "../../../services/subscribtion";

export default {
	name: "SubscriptionMapView",
	components: { BillboardMap, BrebAsyncImage, SearchBillboard, SubscribePlan, AdListingModal },
	props: {
		stepsMode: {
			type: Boolean,
			default: false,
		},
		quickView: {
			type: Boolean,
			default: false,
		}
	},
	data: () => {
		return {
			dialog: false,
			billboardMap: null,
			subscribed: true,
			plan: null,
			selectedBillboards: [],
			showUpgradePlan: false,
			showSingUpModal: false,
			billboards: {},
			license: null,
			maximumSeatSelection: 0,
			maximumAmountSeatError: null,
			enterprisePlanCode: null,
			enterprisePlanCodeError: null,
			isEnterprisePlan: false,

			// for loading
			loadingCurrentPlan: false,
			loadingLicense: true,
			loadingBillboards: true,
		}
	},

	mounted() {
		this.updateRouters();
		this.initCurrentPlan();

		// console.log('mounted this.getCurrentPlanId() = ',  this.getCurrentPlanId())

		this.maximumSeatSelection = this.standardPlans.reduce((max, current) => {
			return current.seat_count > max ? current.seat_count : max;
		}, 0);

		if (this.getCurrentPlanId()) {
			this.loadingCurrentPlan = true;
			this.$store.dispatch('api/license/getStandardPlan', { planId: this.getCurrentPlanId() })
				.then((plan) => {
					this.plan = plan;

				})
				.finally(() => {
					this.loadingCurrentPlan = false;
				});
		}

		// if (this.getCurrentListingId()) {
		this.$store.dispatch('api/listings/requestSelfListingsAll');
		// }

		// if (this.getUrlBoardsId()) {

		// }


		this.initBillboardsAll()
			.then(() => {
				this.initLicense();
			})

		this.initBillboards();

		// this.billboardMap = this.$refs.billboardMap;

		// console.log('view billboardMap = ', this.billboardMap)
		// console.log('view this.$refs = ', this.$refs)

		// setTimeout(()=>{
		//     this.billboardMap = this.$refs.billboardMap
		// }, 3000)


		const interval = setInterval(() => {
			if (this.$refs.billboardMap) {
				this.billboardMap = this.$refs.billboardMap;

				// console.log(this.$refs.billboardMap)
				// VueComponent{}
				// console.log(this.$refs)
				// {nav: VueComponent}
				clearInterval(interval)
			}
		}, 50)

	},

	methods: {
		updateRouters() {
			subscribtion.setRoute(this.$route);
			subscribtion.setRouter(this.$router);
		},

		getCurrentPlanId() {
			this.updateRouters();
			return subscribtion.getUrlPlanId();
		},

		getUrlBoardsId() {
			this.updateRouters();
			return subscribtion.getUrlBoardsIds();
		},

		getSelectedBoardsId() {
			return this.selectedBillboards.map(v => v.id);
		},

		getCurrentListingId() {
			this.updateRouters();
			return subscribtion.getUrlListingId();
		},

		showSubscribed() {
			this.subscribed = !this.subscribed
		},

		initCurrentPlan() {
			if (this.getCurrentPlanId()) {
				this.loadingPlan = true;
				this.$store.dispatch('api/license/getStandardPlan', { planId: this.getCurrentPlanId() })
					.then((plan) => {
						this.plan = plan;
					})
					.finally(() => {
						this.loadingPlan = false;
					});
			}
		},

		initBillboards() {
			let billboardsIds = this.getUrlBoardsId();
			this.$store.dispatch('api/billboard/getByIds', { ids: billboardsIds })
				.then((billboards) => {
					//this.selectedBillboards = billboards;
					this.selectedBillboards.push(...billboards);
					this.$set(this, "selectedBillboards", this.selectedBillboards);

				})
				.finally(() => {
					this.loadingBillboards = false;
				});
		},

		clickAdvertiseHere({ billboard }) {
			// if (this.plan && this.selectedBillboards.length === this.plan.seat_count) {
			//     this.showUpgradePlan = true;
			//     return;
			// }

			this.addBillboard(billboard);
		},

		addBillboard(billboard) {
			if (this.getSelectedBillboard(billboard.id)) {
				return;
			}

			if (this.selectedBillboards.length >= this.maximumSeatSelection) {
				this.maximumAmountSeatError = "Unable to add a board, please contact administrator to get more boards. Sales@MyBREB.com"
				this.$toasted.error('Unable to add a board, please contact administrator to get more boards.');
				return;
			}
			this.maximumAmountSeatError = null;
			this.selectedBillboards.push(billboard);
			this.$set(this, "selectedBillboards", this.selectedBillboards);
			this.$helpers.routerAppendGetParameters(this.$router, this.$route, { billboards: this.getSelectedBoardsId().join() });
			this.updateCurrentPlan();
		},

		removeBillboard(billboard) {
			if (this.getSelectedBillboard(billboard.id) === undefined) {
				return;
			}
			this.maximumAmountSeatError = null;
			this.selectedBillboards.splice(this.selectedBillboards.findIndex(v => v.id === billboard.id), 1);
			this.$helpers.routerAppendGetParameters(this.$router, this.$route, { billboards: this.getSelectedBoardsId().join() });
			this.updateCurrentPlan();
		},

		getSelectedBillboard(id) {
			return this.selectedBillboards.find(v => v.id === id);
		},

		sendToCheckout() {
			if (!this.loggedIn) {
				this.showSingUpModal = true;
				return;
			}

			if (!this.getCurrentListingId()) {
				if (this.listings.length === 0) {
					// show the modal and redirect to create a new listing
					this.$refs.adListingModal.show();
					return;

				} else if (this.listings.length === 1) {
					this.$router.push({ query: { listingId: this.listings[0].id } });
				} else {
					this.$refs.subscribePlan.show();
				}
				return;
			}
			// console.log('sendToCheckout this.$route.query = ', JSON.stringify(this.$route.query));
			// console.log('sendToCheckout this.getUrlBoardsId() = ', this.getUrlBoardsId());

			if (this.stepsMode) {
				this.$helpers.routerAppendGetParameters(
					this.$router,
					this.$route,
					{ billboards: this.getUrlBoardsId().join() }
				);
				this.$emit('selectLocation');
				this.$emit('mountCheckoutViewComponent');
				this.$emit('clickNext');
			} else {
				this.$helpers.routerAppendGetParameters(
					this.$router,
					this.$route,
					{ billboards: this.getUrlBoardsId().join() },
					'CheckoutView');
			}
		},


		upgradePlan() {
			subscribtion.deleteLSAll();

			let query = subscribtion.mapForUrlQuery(subscribtion.getUrlAll());
			query.showMoreThan = this.plan.seat_count;

			this.$helpers.routerAppendGetParameters(this.$router, this.$route, query, 'Subscriptions');
			this.showUpgradePlan = false;
		},

		clickSignUp() {
			subscribtion.setLSPlanIdFromUrl();
			subscribtion.setLSBillboardsIdsFromUrl();

			this.$router.push({ name: "Register" });
			this.showSingUpModal = false
		},

		initBillboardsAll() {
			return this.$store.dispatch('api/billboard/getAll', {})
				.then((response) => {
					// console.log('initBillboardsAll response = ', response)
					response.data.results.forEach(billboard => {
						this.$set(this.billboards, billboard.id, billboard);
					});
				})
				.catch(e => {
					this.$toasted.error('Unable to retrieve a list of billboards. It is possible the server is unreachable. ', e);
				});
		},


		getNotSubscribedBillboardsIds() {
			return this.selectedBillboards
				.filter((billboard) => {
					// console.log('getNotSubscribedBillboardsIds billboard = ', JSON.stringify(billboard))
					return !this.isBoardSubscribed(billboard);
				})
				.map(billboard => billboard.id);
		},

		initLicense() {
			let listingId = this.getCurrentListingId();
			if (listingId) {
				// console.log('001')
				this.$store.dispatch('api/license/obtainLicenseForListing', { listingId: listingId })
					.then((license) => {
						this.license = license;
						// license.seats = [7, 13];

						// console.log('this.license = ', this.license)
						if (!this.license) {
							return;
						}

						license.seats.forEach((billboardId) => {
							let billboard = this.getBillboardById(billboardId);
							this.addBillboard(billboard);
						})
					})
					.finally(() => {
						this.loadingLicense = false;
					});

			} else {
				this.loadingLicense = false;
			}

		},

		getBillboardById(id) {
			return this.billboards[id];
			// return this.billboards.find(v => v.id === id);
		},

		isBoardSubscribed(billboard) {
			// console.log('isBoardSubscribed billboard = ', billboard.id)
			if (!this.license) {
				return null;
			}

			// console.log('isBoardSubscribed this.license.seats = ', JSON.stringify(this.license.seats))

			return this.license.seats.some((billboardId) => {
				// console.log('isBoardSubscribed 002 billboard = ', billboard.id)
				// console.log('isBoardSubscribed 002 billboardId = ', billboardId)
				// console.log('isBoardSubscribed ===   = ', billboard.id ===  billboardId)

				return billboard.id === billboardId;
			});
		},


		updateCurrentPlan() {
			let v = this.selectedBillboards;
			if (!this.plan || !this.standardPlans || !this.enterprisePlans) {
				return;
			}
			let selectedSeats = v.length;

			// if (selectedSeats <= this.plan.seat_count){
			//     // console.log('enough seats !');
			//
			//
			//         let newQuery = {...this.$route.query, 'planId': null}
			//         this.$router.push({query: newQuery});
			//         this.plan = null;
			//         this.initCurrentPlan();
			//         this.$toasted.success('You use your current license plan now');
			// }

			if (!this.isEnterprisePlan) {
				let filteredStandards = this.standardPlans
					.filter((plan) => {
						return plan.seat_count >= selectedSeats;
					})
					.sort((a, b) => {
						return a.seat_count > b.seat_count
					});
				let nextPlan = filteredStandards[0];

				if (!this.plan || (this.plan && this.plan.seat_count < nextPlan.seat_count)) {
					let newQuery = { ...this.$route.query, 'planId': nextPlan.id }
					this.$router.push({ query: newQuery });
					this.initCurrentPlan();
					this.$toasted.success('Plan upgraded');
				}

				if (this.plan && this.plan.seat_count > nextPlan.seat_count) {
					let newQuery = { ...this.$route.query, 'planId': nextPlan.id }
					this.$router.push({ query: newQuery });
					this.initCurrentPlan();
					this.$toasted.success('Plan downgraded');
				}
			}
		},
		clickApplyEnterprisePlanCode() {
			this.loadingCurrentPlan = true;
			this.$store.dispatch('api/license/getPlanByPlanId', {
				planId: this.enterprisePlanCode
			}).then((res) => {
				this.plan = res.data;
				let newQuery = { ...this.$route.query, 'planId': this.plan.id }
				this.$router.push({ query: newQuery });

				this.maximumSeatSelection = this.plan.seat_count;
				this.enterprisePlanCode = null;
				this.enterprisePlanCodeError = null;
				this.maximumAmountSeatError = null;
				this.isEnterprisePlan = true;
			}).catch((e) => {
				this.enterprisePlanCodeError = "This enterprise package code does not exist, please contact administrator to update your enterprise package code."
				this.isEnterprisePlan = false;
			}).finally(() => {
				this.loadingCurrentPlan = false;
			});
		},
		clickViewMarketingPackage() {
			this.$emit('closeDialog');
			if (this.$route.fullPath !== '/subscriptions') {
				this.$router.push('/subscriptions');
			}
		},
	},

	computed: {
		listing() {
			let id = parseInt(this.getCurrentListingId());
			// console.log('computed listing id = ', id);
			let ret = this.$store.getters['api/listings/getSelfListingById']({ id: id })
			// console.log('computed listing ret = ', ret);
			return ret;
		},

		loggedIn() {
			return this.$store.getters['user/getLoggedIn'];
		},

		listings() {
			return this.$store.getters['api/listings/getSelfListingsMerchant'];
		},

		selectedBillboardsSorted() {
			return this.selectedBillboards.sort((a) => {
				if (this.isBoardSubscribed(a)) {
					return -1;
				}
				return 0;
			});
		},

		loader() {
			return this.loadingCurrentPlan || this.loadingBillboards || this.loadingLicense;
		},

		standardPlans() {
			return this.$store.getters['api/license/getStandardPlans'];
		},

		enterprisePlans() {
			return this.$store.getters['api/license/getEnterprisePlans'];
		},

	},
}