<template>
	<v-img :contain="contain" ref="image" :src="src" v-bind="$attrs" :eager="eager" v-on="$listeners"
		@error="needsFallback = true; error = $event" :height="height" :width="width" @load="handleLoad"
		:class="classes">
		<slot name="img-overlay" />
	</v-img>
</template>

<script>
export default {
	name: "BrebAsyncImage",
	props: {
		id: {
			type: Number,
			default: () => 0
		},
		/**
		 * fq = Full quality (source)
		 * hq = High Quality (1080p)
		 * mq = Medium Quality (720p)
		 * thumbnail = Thumbnail (360p)
		 */
		quality: {
			type: String,
			required: false,
			default: () => 'fq'
		},
		rounded: {
			type: Boolean,
			default: () => false
		},
		forceFill: {
			type: Boolean,
			default: () => false
		},
		externalSource: {
			type: String
		},
		eager: {
			type: Boolean,
			default: () => false
		},
		contain: {
			type: Boolean,
			default: () => false
		},
		height: {},
		width: {}
	},
	data: function () {
		return {
			needsFallback: false,
			error: '',
			loaded: false,
		}
	},
	computed: {
		src: function () {
			if (this.externalSource)
				return this.externalSource;

			if (this.needsFallback)
				return this.fullQualityURL;

			switch (this.quality) {
				case 'fq':
					return this.fullQualityURL;
				case 'hq':
					return this.highQualityURL;
				case 'mq':
					return this.mediumQualityURL;
				case 'thumbnail':
					return this.thumbnailURL;
				default:
					return this.fullQualityURL;
			}
		},
		fullQualityURL: function () {
			return this.$helpers.getFullQualityURL(this.id);
		},
		highQualityURL: function () {
			return this.$helpers.getImageURL(this.id);
		},
		mediumQualityURL: function () {
			return `${this.$helpers.getBaseImageURL()}/thumbnails/${this.id}_720p`
		},
		thumbnailURL: function () {
			return this.$helpers.getThumbnailURL(this.id);
		},
		classes: function () {
			return {
				'force-fill': this.forceFill,
				'image-rounded': this.rounded
			}
		}
	},
	methods: {
		handleLoad: function (ev) {
			this.loaded = true;
			this.$emit('imageload', ev)
		}
	},
}
</script>

<style scoped>
.image-rounded {
	border-radius: 15px;
}

.force-fill /deep/ .v-image__image {
	background-size: 100% 100% !important;
}
</style>