import LocationAutocomplete from "@/components/geography/LocationAutocomplete";

const icon = require('@/assets/breb.rect.logo.new-w200.png');
const clusterIcon = require('@/assets/breb-cluster-2.png');

export default {
	name: "BillboardMap",
	components: {LocationAutocomplete},
	props: {
		location: {},
		selectedBillboards: [],
		hideAdvertiseHere: false,
	},
	data: function () {
		let location = this.location ? this.location : {}
		return {
			dataQuery: {
				lat: location.latitude,
				lng: location.longitude,
				name: location.geolocationName,
			},
			mapMarkerCircleHidden: {},
			cluster: {},
			billboards: {},
			map: null,
			target: null,
			targetValid: false,
			center: {
				lat: 43.016,
				lng: -79.261
			},
			clusterStyles: [
				{
					textColor: "black",
					url: clusterIcon,
					height: 120,
					width: 120,
					textSize: 20,
					backgroundPosition: "-20 -5"
				}
			],
			
			infoWindowPos: {
				lat: 0,
				lng: 0
			},
			infoWinOpen: false,
			currentInfoWindowPos: false,
			
			infoOptions: {
				pixelOffset: {
					width: 20,
					height: -20
				},
				items: [],
			},
		}
	},
	computed: {
		
		googleMapOptions: function () {
			return {
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				disableDefaultUi: true,
				fullscreenControl: false,
			}
		},
		
		markerOptions: function () {
			return {
				icon: {
					url: icon,
					anchor: {
						x: 25,
						y: 15
					},
					// scaledSize: {height: 30, width: 50},
					scaledSize: {height: 30, width: 200 / 2.4}
					// scaledSize: {height: 70, width: 50},
				},
			}
		},
		
		billboardSearchItems() {
			return Object.values(this.billboards).map(billboard => {
				return {
					text: billboard.name,
					value: billboard.id
				}
			})
		},
		
		isMarkerSelected() {
			return (marker) => {
				let billboard = marker.billboard;
				if (this.selectedBillboards === null) {
					return false;
				}
				
				return this.selectedBillboards.some(v => v.id === billboard.id);
			}
			
		}
	},
	methods: {
		
		getBillboards() {
			this.$store.dispatch('api/billboard/getAll', {})
			.then((response) => {
				response.data.results.forEach(billboard => {
					this.$set(this.billboards, billboard.id, billboard);
				});
			})
			.catch(e => {
				this.$toasted.error('Unable to retrieve a list of billboards. It is possible the server is unreachable. ', e);
			});
		},
		setTargetBillboard(billboard) {
			if (billboard == null)
				return;
			
			this.target = billboard;
			this.center = billboard.point;
		},
		
		clusterCalculator(markers, numStyles) {
			
			let index = 0;
			// let title = "";
			let count = markers.length.toString();
			
			let dv = count;
			while (dv !== 0) {
				dv = parseInt(dv / 10, 10);
				index++;
			}
			
			index = Math.min(index, numStyles);
			return {
				text: count,
				index: index,
				title: count
			};
		},
		
		getGmapCluster() {
			return this.$refs.gmapCluster.getClusters();
		},
		getGmapClusters() {
			return this.$refs.gmapCluster.$clusterObject.clusters_;
		},
		
		getGmapMultiClusters() {
			return this.getGmapClusters().filter((cluster) => {
				return cluster.markers_.length > 1;
			});
		},
		
		updateCluster() {
			for (let key in this.mapMarkerCircleHidden) {
				delete this.mapMarkerCircleHidden[key];
			}
			
			this.getGmapMultiClusters().forEach((cluster) => {
				cluster.getMarkers().forEach((marker) => {
					this.mapMarkerCircleHidden[parseInt(marker.getTitle())] = true;
				});
			});
		},
		
		toggleInfoClusterWindow: function (cluster) {
			this.infoOptions.items = cluster.getMarkers().map((marker) => {
				let id = marker.getTitle();
				let billboard = this.billboards[id];
				
				return {
					name: billboard.name,
					description: billboard.description,
					billboard: billboard
				}
			});
			
			this.infoWindowPos = {
				lat: cluster.getCenter().lat(),
				lng: cluster.getCenter().lng(),
			};
			
			let idx = JSON.stringify(this.infoWindowPos);
			
			if (this.currentInfoWindowPos === idx) {
				this.infoWinOpen = !this.infoWinOpen;
			}
			//if different marker set infowindow to open and reset current marker index
			else {
				this.infoWinOpen = true;
				this.currentInfoWindowPos = idx;
			}
			
		},
		
		
		toggleInfoMarkerWindow: function (billboard, idx) {
			
			
			this.infoOptions.items = [
				{
					name: billboard.name,
					description: billboard.description,
					billboard: billboard
				}
			];
			
			this.infoWindowPos = billboard.point;
			
			if (this.currentInfoWindowPos === idx) {
				this.infoWinOpen = !this.infoWinOpen;
			}
			//if different marker set infowindow to open and reset current marker index
			else {
				this.infoWinOpen = true;
				this.currentInfoWindowPos = idx;
			}
		},
		
		markerHover: function (event) {
			event.domEvent.toElement.closest("div").title = '';
		},
		
		hideInfoWindow: function () {
			this.infoWinOpen = false;
		},
		
		showInfoWindow: function (marker, idx) {
			this.infoWindowPos = marker.point;
			this.infoOptions.content = marker.name;
			this.infoOptions.description = marker.description;
			this.infoWinOpen = true;
			this.currentInfoWindowPos = idx;
		},
		
		clickZoomBillboard(billboard) {
			if (!this.hideAdvertiseHere) {
				this.center = billboard.point;
				this.toggleInfoMarkerWindow(billboard);
				this.setZoom(11);
			} else {
				this.$router.push({path: '/subscriptions'});
			}
			
		},
		
		setZoom(zoom) {
			this.map.setZoom(zoom - 1);
			setTimeout(() => {
				this.map.setZoom(this.map.getZoom() + 1);
			})
		},
		
		zoomUpdate() {
			this.setZoom(this.map.getZoom() - 1);
			setTimeout(() => {
				this.setZoom(this.map.getZoom() + 1);
			}, 1)
		},
		
		getBoundsWithBillboard(latLngBoundsLiteral, center, billboards = this.billboards) {
			if (Object.keys(billboards).length === 0) {
				return latLngBoundsLiteral;
			}
			
			let closestBillboard = null;
			let closestDistance = null;
			let ret = {...latLngBoundsLiteral}
			
			for (let key in billboards) {
				let billboard = billboards[key];
				let iD = this.distance(center.lng, center.lat, billboard.point.lng, billboard.point.lat)
				
				if (closestDistance === null) {
					closestDistance = iD;
					closestBillboard = billboard;
				}
				
				if (closestDistance > iD) {
					closestDistance = iD;
					closestBillboard = billboard;
				}
			}
			
			ret.east = closestBillboard.point.lng > ret.east ? closestBillboard.point.lng : ret.east;
			ret.west = closestBillboard.point.lng < ret.west ? closestBillboard.point.lng : ret.west;
			ret.north = closestBillboard.point.lat > ret.north ? closestBillboard.point.lat : ret.north;
			ret.south = closestBillboard.point.lat < ret.south ? closestBillboard.point.lat : ret.south;
			
			return ret;
		},
		
		distance(ax, ay, bx, by) {
			let ab = (bx - ax) ** 2 + (by - ay) ** 2;
			return Math.pow(ab, 0.5)
		},
		
		setMapZoomRaw(maxZoom) {
			this.map.setOptions({maxZoom: maxZoom});
		},
		
		clickAdvertiseHere(marker) {
			let billboard = marker.billboard;
			this.$emit('clickAdvertiseHere', {billboard: billboard});
			this.infoWinOpen = false;
		},
		
		handleRightClick() {
			//
		},
		
		infoCloseClick() {
			this.infoWinOpen = false;
			this.currentInfoWindowPos = false;
		},
	},
	
	mounted() {
		this.getBillboards();
		this.$refs.map.$mapPromise.then((map) => {
			this.map = map;
			
			map.addListener('contextmenu', this.handleRightClick);
			let events = [
				"bounds_changed",
				"resize",
				"zoom_changed",
				"center_changed",
			];
			
			events.forEach((eventName) => {
				map.addListener(eventName, this.updateCluster);
			})
			
			this.$refs.gmapCluster.$clusterPromise.then((cluster) => {
				this.cluster = cluster;
			});
		});
	},
}