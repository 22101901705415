import axios from "axios";

export default {
	namespaced: true,
	state: {},
	mutations: {},
	getters: {},
	actions: {
		async getSponsor(ctx, {lat, lng}) {
			return axios.post(`/api/v1/billboard/sponsor`, {
				lat: lat,
				lng: lng,
			});
		},
		
		async search(ctx, {size}) {
			return axios.post(`/api/v1/billboard/search`,
				{},
				{
					params: {
						size: size,
					}
				});
		},
		
		async getAll(ctx, {size = 3000}) {
			return this.dispatch('api/billboard/search', {size: size});
		},
		
		async getByIds(ctx, {ids = []}) {
			return this.dispatch('api/billboard/getAll', {})
			.then((response) => {
				console.log('getByIds response = ', response)
				console.log('getByIds ids = ', ids)
				return response.data.results.filter(item => ids.includes(item.id))
			});
			
			// TODO: do nor remove, will use after get access to the endpoint for not SignIn user
			// let requests = ids.map((id)=>{
			//     return axios.get(`/api/v1/billboard/${id}`)
			// });
			//
			// return axios.all(requests)
			//     .then((response) => {
			//         return response.map(v => v.data);
			//     })
		},
	},
};