import axios from "axios";

export default {
    namespaced: true,
    state: {
        cart: null,
    },
    actions: {
        async addProductToCart(ctx, { itemId, quantity }) {
            return axios.post(`/api/v1/marketplace/cart/`, {
                item: itemId,
                quantity
            });
        },

        async getCart(ctx) {
            return axios.get(`/api/v1/marketplace/cart/`).then(result => {
                if (result.data) {
                    ctx.commit('setCart', result.data);
                }
                return result;
            }).catch((err) => {
                ctx.commit('setCart', { id: null, items: [] });
            });
        },

        async checkout(ctx, { options }) {
            return axios.post(`/api/v1/marketplace/cart/checkout`, options);
        },

        async updateQuantityToCart(ctx, { itemId, quantity }) {
            return axios.post(`/api/v1/marketplace/cart/`, { item: itemId, quantity });
        },

        async removeProductFromCart(ctx, { itemId }) {
            const id = itemId
            return axios.delete(`/api/v1/marketplace/cart/${id}`);
        },

        async getTax(ctx, { checkout }) {
            return axios.post(`/api/v1/marketplace/cart/tax`, checkout);
        }
    },
    getters: {
        getCart(state) {
            return state.cart;
        },
    },
    mutations: {
        setCart(state, payload) {
            state.cart = payload;
        },
    }
};
